import React, {useState} from "react"
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import GalleryList from "../components/Gallery/GalleryList";
import GalleryItem from "../components/Gallery/GalleryItem";
import { Container } from "../components/Layout/Container";
import { Section } from "../components/Layout/Section";
import * as portfolioPageStyles from "../styles/portfolios-page.module.scss"
import { sortModulesAndAssets } from "../../utils/helpers";



const IndexPage = ({pageContext, data, path}) => {
  // const portfolios = data.allPortfolio.nodes
    const portfolios = sortModulesAndAssets(data.allPortfolio.nodes)
    const areas = data.allGallery.group.map(g => {
        return g.nodes[0].tags[0]
    })
    const projectAreas = areas.map(n => {
        return {
            uid: n.uid,
            image:  n.tagImage?.childImageSharp,
            title:  n.locales.find(l => l.iso === pageContext.lang).title,
            value:  `/interiors-exteriors/areas/${n.locales.find(l => l.iso === pageContext.lang).title.split(" ").join("-").toLowerCase()}`
        }
    })
    // console.log("PROJECT AREAS: ", data.allPortfolio.nodes)
   const [toggleTab, setToggleTab] = useState(false)

  return (
    <Layout pathName={path} insidePage breadCrumbLinks={pageContext.breadCrumb}>
      <Container width="full-size">
       <Section>
           <div className={portfolioPageStyles.projectGallery}>
               <div className={portfolioPageStyles.tabs}>
                   <button onClick={() => setToggleTab(false)} disabled={!toggleTab} className="gallery-btn">Projects</button>
                   <button onClick={() => setToggleTab(true)} disabled={toggleTab} className="project-areas">Areas</button>
               </div>
               <div className={portfolioPageStyles.galleryWrapper}>
                   { !toggleTab && <div className={portfolioPageStyles.galleryAll}>
                       { portfolios.length > 0 && <GalleryList>
                           {portfolios.map(p => {
                               const { title, value } = p.locales.find(l => l.iso === pageContext.lang)
                               return <GalleryItem subText="View project" key={p.uid} showInfo image={p.imageFile} title={title} projectUrl={p.locales.find(l => l.iso === pageContext.lang).value} />
                           })}
                       </GalleryList>}
                   </div>}
                   { toggleTab && <div className={portfolioPageStyles.areas}>
                       { projectAreas.length > 0 && <GalleryList>
                           {projectAreas.map(p => {
                               return <GalleryItem subText="View all" key={p.uid} showInfo image={p.image} title={p.title} projectUrl={p.value} />
                           })}
                       </GalleryList>}
                   </div>}
               </div>
           </div>
       </Section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
    query($templateName: String)  {
        template(name: {eq: $templateName}) {
            locales {
                iso
                metaDescription
                metaKeywords
                metaTitle
                value
            }
        }
        allPortfolio {
            nodes {
                uid
                position
                locales {
                    title
                    content
                    description
                    iso
                    metaDescription
                    metaKeywords
                    metaTitle
                    value
                }
                portfolioUrl
                position
                uid
                imageFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                    publicURL
                }
                gallery {
                    galleryImages {
                        galleryImage {
                            uid
                            childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH)
                            }
                        }
                    }
                    assets {
                        locales {
                            alt
                            iso
                        }
                    }
                    tags {
                        tagImage {
                            childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH)
                            }
                        }
                        locales {
                            iso
                            title
                        }
                    }
                }
                asset {
                    locales {
                        alt
                        iso
                    }
                }
                additionalData {
                    key
                    locales {
                        iso
                        value
                    }
                }
                authorName
                authorUrl
                category {
                    locales {
                        iso
                        title
                    }
                    uid
                }
            }
        }
        allGallery {
            group(field: tags___locales___title) {
                nodes {
                    tags {
                        uid
                        locales {
                            iso
                            title
                        }
                        tagImage {
                            childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH)
                            }
                        }
                    }
                }
            }
        }
    }
`

export const Head = ({data, pageContext}) => {
    const { metaTitle, metaDescription} = data.template.locales.find(l => l.iso === pageContext.lang)
    return  <Seo title={metaTitle} description={metaDescription} />
}

export default IndexPage
